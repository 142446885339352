//@ts-ignore
const map_center = center

//@ts-ignore
const map_zoom = zoom

// Initialize and add the map
function initMap(): void {

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: map_zoom,
        center: map_center,
      }
    );

    // The marker, positioned at Uluru
    const marker = new google.maps.Marker({
      position: map_center,
      map: map,
    });
  }

//   declare global {
//     interface Window {
//       initMap: () => void;
//     }
//   }

global.initMap = initMap;